// External library imports
import * as React from 'react';
import {Grid, Paper, ThemeProvider} from '@mui/material';
import {Outlet} from 'react-router-dom';

// Style and asset imports
import logo from '../../../assets/img/criteria-prosperia-img.png';
import themeCriteria from 'theme/criteria';
import {MuiGridRoot} from './styles/login';

const LoginLayout = () => {

  return (
    <ThemeProvider theme={themeCriteria}>
      <MuiGridRoot container component='main'>
        <Grid item xs={false} sm={false} md={7} sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          maxWidth: '100% !important', 
          flexGrow: '1 !important'
        }}>
          {/*<MuiDivImageGrid>*/}
          {/*  <img src={logo} alt='Criteria' />*/}
          {/*</MuiDivImageGrid>*/}
        </Grid>
        <Grid item xs={12} sm={12} md={5} component={Paper} square sx={{
          display: 'flex', // Added for layout purposes, adjust as needed
          flexDirection: 'column', // Added for layout purposes, adjust as needed
          alignItems: 'center', // Added for layout purposes, adjust as needed
          justifyContent: 'center', // Ensures the form is centered, adjust as needed
          minWidth: '300px', // Ensures a minimum width for the form
          width: '556px !important', 
          maxWidth: '556px !important'
        }}>
          <Outlet />
        </Grid>
      </MuiGridRoot>
    </ThemeProvider>
  );
};

export default LoginLayout;
