// React and External Libraries
import * as React from 'react';

// API and Axios Imports
import axios from '../../api/axios/axiosInstance';

// Custom Hooks and Contexts
import { useAuth } from '../providers/AuthProvider';

// * Maybe we need more than one axios instance
const axiosInstances = [axios];

const TokenInterceptor = ({ children, accessToken: getAccessToken }) => {
  const { logout, isAuthenticated } = useAuth();

  React.useEffect(() => {
    const addResponseInterceptor = (axiosInstances) =>
      axiosInstances.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const shouldRefreshToken = isAuthenticated && error.response?.status === 401;

          if (shouldRefreshToken) {
            return logout();
          }

          return Promise.reject(error.response || error);
        }
      );

    const errorInterceptors = axiosInstances.map((instance) => addResponseInterceptor(instance));

    return () => {
      axiosInstances.forEach((instance, i) =>
        instance.interceptors.response.eject(errorInterceptors[i])
      );
    };
  }, [isAuthenticated, logout]);

  React.useEffect(() => {
    const addRequestInterceptor = (axiosInstance) =>
      axiosInstance.interceptors.request.use(
        (config) => {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${getAccessToken}`,
            },
          };
        },
        (error) => Promise.reject(error)
      );

    const authInterceptors = axiosInstances.map((instance) => addRequestInterceptor(instance));

    return () => {
      axiosInstances.forEach((instance, i) =>
        instance.interceptors.request.eject(authInterceptors[i])
      );
    };
  }, [getAccessToken]);

  return children;
};

export default TokenInterceptor;
