// External library imports
import {Divider, Grid, styled} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// Internal component and function imports
import {TextField} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

export const MuiDivPaper  = styled("div")(({ theme }) => ({
  margin: theme.spacing(10, 15, 1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '327px'
}));

export const MuiForm = styled("form")(({ theme }) => ({
  width: '60%',
  marginTop: theme.spacing(0),
}));

export const MuiGridContainerButtons = styled(Grid)(({ theme }) => ({
  display: 'flex',
}));

export const MuiGoogleButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  marginTop:'9px !important',
  border: '1px solid #6793A5',
  width: '327px',
  height: '40px',
  borderRadius: '20.5px',
  justifyContent: 'flex-start',
  paddingLeft: '10px',
  paddingTop: '8px',
  paddingBottom: '8px',
  color: 'black !important',
}));
export const MuiButtonColored = styled(Button)(({ theme }) => ({
  color: 'white !important',
  marginTop:'5px !important',
  marginBottom: '15px',
  width: '312px',
  height: '40px',
  borderRadius: '20.5px',
  background: '#00BBD4',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Montserrat'
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  height: 'auto',
  borderRadius: '4px',
  width: '312px',
  fontSize: '12px',
  fontFamily: 'Montserrat',
  textTransform: 'none',
  color: '#143440',
  '& .MuiOutlinedInput-notchedOutline':{
    borderColor:'#325D6C'
  },
  '& .MuiInputBase-root':{
    height:'56px'
  },
  '& input::placeholder': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily:'Montserrat',
    letterSpacing:'0.5px',
    color:'#143440'
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily:'Montserrat',
    letterSpacing:'0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& legend': {
    fontSize: '14px',
  },
}));

export const MuiVisibilityIcon = styled(VisibilityOutlinedIcon)(({ theme }) => ({
  color:'#254957',
  width: '24px',
  height: '24px',
}));

export const MuiVisibilityOffIcon = styled(VisibilityOffOutlinedIcon)(({ theme }) => ({
  color:'#254957',
  width: '24px',
  height: '24px',
}));

export const MuiDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
}));

export const MuiGridRoot = styled(Grid)(({ theme }) => ({
  height: '100vh',
  justifyContent:'space-between'
}));

export const circularProgressStyle = {
  color: 'black'
}

export const spacingStyle = {
  // marginTop:'-40px'
}

export const buttonStyle = {
  width:'100%',
  fontWeight: 400,
  height: '16px',
  fontSize: '12px',
  textTransform: 'none',
  color: '#143440',
  fontFamily: 'Montserrat',
  lineHeight: '14.63px',
  letterSpacing: '0.048px',
}

export const styleTitle = {
  color: '#00BBD4',
  fontSize: '45px',
  fontWeight:600,
  textTransform: 'uppercase',
  lineHeight: '52.83px',
}

export const buttonSocial = {
  fontSize: '13px',
  fontFamily: 'Montserrat',
  width: '100%',
  textAlign: 'center',
  marginLeft: '-1em',
  fontStyle: 'normal',
  textColor: '212121',
  lineHeight: '20px'
}
export  const langContainer ={
  position: 'absolute',
  right: '125px',
  top: '28px'
}

export const textOR ={
  width: '312px',
  textAlign: 'center',
  margin: '14px 0px 18px 0px',
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '19.5px',
  letterSpacing: '0.1625px',
}

export const subTitleStyle = {
  maxWidth:'80%',
  fontSize: '21px',
  fontFamily: 'Raleway',
  fontWeight:500,
  width: '100%',
  textAlign: 'center',
  lineHeight: '24.65px',
  letterSpacing: '0.0315px',
}

export const resetPassTitleStyle = {
  fontSize: '24px',
  marginLeft: '0px !important',
  fontWeight: 500,
  textAlign: 'left',
  lineHeight: '28.18px',
  marginBottom: '20px',
  marginTop: 0,
}
export const gridResetContainerStyle = {
  width: '328px',
  marginTop:'-200px'
}

export const inputAdornmentStyle = {
  color: '#6793A5',
  fontSize:'24px'
}
export const containerStyle = {
  width: '328px',
  marginTop:'-200px'
}

export const arrowBackStyle = {
  width: '24px',
  height: '24px',
  color: '#212121'
}

export const forgotStyle = {
  fontSize: '26px',
  fontWeight: 700,
  textAlign: 'left',
  marginBottom: '17px',
  lineHeight: '30.52px',
}

export const forgotInfoStyle = {
  fontSize: '12px',
  lineHeight: '14.06px',
  fontFamily: 'Montserrat',
  letterSpacing: '0.05px',
}

export const fieldsForm = {
  height: '56px',
  width: '328px',
  fontSize: '12px',
  fontFamily: 'Monserrat',
  textTransform: 'none',
  color: '#143440',
  marginBottom: '28px',
}

