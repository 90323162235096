// React and External Libraries
import * as React from 'react';

// MUI Components
import {Divider, InputAdornment, MenuItem} from '@mui/material';

import {useTranslation} from 'components/providers/TranslationProvider';

// Style and asset imports
import {
  MuiExpandMoreIcon,
  MuiInputBase as BootstrapInput,
  MuiSelect
} from './styles/languagwSelector';

import flagES from 'assets/img/spanish.png'
import flagEN from 'assets/img/english.png'
import flagBR from 'assets/img/brazil.png'
import flagFR from 'assets/img/french.png'
import {button} from "./styles/global";

const LanguageCode = ({language}) => {
  switch (language) {
    case 'spanish':
      return 'ES';
    case 'portuguese':
      return 'PT';
    case 'french':
      return 'FR';
    case 'arabic':
      return 'AR';
    default:
      return 'EN';
  }
}
const LanguageSelector = ({ textColor = 'black' }) => {
  const { languages, lng, changeLanguage } = useTranslation();

  const FlagIcon = ({ language }) => {
    switch(language) {
      case 'spanish':
        return <img src={flagES} alt="ES" />;
      case 'portuguese':
        return <img src={flagBR} alt="BR" />;
      case 'french':
        return <img src={flagFR} alt="FR" />;
      default:
        return <img src={flagEN} alt="EN" />;
    }
  };

  return (
      <MuiSelect
        value={lng}
        onChange={(e) => changeLanguage(e.target.value)}
        input={<BootstrapInput style={{ color: textColor }}  />}
        IconComponent={(props) => <MuiExpandMoreIcon {...props} style={{ color: textColor }}  />}
        sx={{...button, paddingRight: 0}}
        startAdornment={ <InputAdornment position="start"><FlagIcon language={lng} /></InputAdornment>}
      >
        <Divider variant='inset' />
        {languages?.map((l) => (
          <MenuItem key={l} disabled={l === lng} value={l} sx={button}>
            <LanguageCode language={l}/>
          </MenuItem>
        ))}
      </MuiSelect>
  );
};

export default LanguageSelector;
