import {styled, Typography} from "@mui/material";

export const MuiTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Raleway',
  textAlign: 'center',
  width: 'auto',
  marginBottom: '8px',
  marginTop: '28px',
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '30.52px',
}));

export const button ={
  fontSize: '14px',
  fontFamily: 'Montserrat'
}
export const body1 ={
  fontSize: '16px',
}
export const headline7 ={
  fontFamily: 'Raleway',
  fontSize: '29px',
  fontWeight: 'bold'
}
